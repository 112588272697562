@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 1);
  z-index: 900;
}

::-webkit-scrollbar-thumb {
  z-index: 900;
  background: #034559;
  background: -webkit-linear-gradient(to bottom, var(--color-golden), #034559);
  background: linear-gradient(to bottom, var(--color-golden), #034559);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  z-index: 900;
  background: var(--color-golden);
  background: -webkit-linear-gradient(to bottom, #034559, var(--color-golden));
  background: linear-gradient(to bottom, #034559, var(--color-golden));
  border-radius: 6px;
}

:root {
  --font-base: "Cormorant Upright", serif;
  --font-alt: "Open Sans", sans-serif;

  --color-golden: #dcca87;
  --color-black: #0c0c0c;
  --color-gray: #545454;
  --color-crimson: #f5efdb;
  --color-grey: #aaaaaa;
  --color-white: #ffffff;
  --icon-color-one: #FC2C9C;
  --icon-color-two: #05CCFC;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
