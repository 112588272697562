.app__register {
  min-height: 100vh;
  background-color: var(--color-black);
  padding: 3rem 4rem;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}

#register {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* Register Button */
.btn__wrapperReg {
  margin: 2rem 0 1rem 0;
  position: relative;
  width: fit-content;
}
.btn__register {
  display: block;
  width: 250px;
  height: 50px;
  font-weight: bold;
  text-decoration: none;

  /* Gradient Color */
  background: #00354a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #403a3e,
    #00354a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #403a3e,
    #00354a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* Gradient Color */

  text-align: center;
  color: var(--color-golden);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid var(--color-golden);
  transition: all 0.35s;
}
.icon__RegBtn {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all 0.35s;
}
.icon__RegBtn svg {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: var(--color-golden);
  z-index: -1;
  transition: all 0.35s;
}
.btn__register:hover {
  width: 200px;
  border: 3px solid var(--color-golden);
  background: transparent;
  color: var(--color-golden);
}
.btn__register:hover + .icon__RegBtn {
  border: 3px solid var(--color-golden);
  right: -25%;
  z-index: 1;
}
/* Register Button */
