.app__sponsors {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
}

.h1_sponsors {
  margin-bottom: 40px;
}
/* .imageDiv_sponsors{
  height: 100px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  margin-right: 50px;
}

.img_sponsors {
  height: 100px;
  width: 200px;
  max-width: 190px;
  max-height: 150px;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  margin-bottom: 5px;
}

.imageDiv_sponsors:hover .img_sponsors{
  transform: scale(1.13);
}
.imageDiv_sponsors:hover .iconsContainer{
  opacity: 1;
} */
.iconsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.chrome {
  margin-left: 20px;
}

.sponsors-container {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 70px;
  width: 60%;
  margin: 5% auto 0%;
  padding-bottom: 7%;
}
.sponsors-card {
  position: relative;
  width: 190px;
  height: 190px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  transition: 0.2s ease-in-out;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  cursor: ponter;

}
.sponsors-card::before {
  content: "";
  position: absolute;
  inset: 0;
  left: -10px;
  margin: auto;
  width: 210px;
  height: 210px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100%);
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

}
.sponsors-card::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100%);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}
.sponsors-card:hover::after {
  filter: blur(30px);
}
.sponsors-card:hover::before {
  transform: rotate(-90deg);
}
.sponsors-img {
  width: 150px;
  height: 150px;
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 1;
}
.textBox {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}
.textBox > .place {
  font-size: 20px;
}
.textBox > .sponsorName {
  font-size: 17px;
}
.sponsors-card:hover > .textBox {
  opacity: 1;
}
.sponsors-card:hover> img {
  width: 170px;
  height: 170px;
  filter: blur(10px);
  animation: anim 2.5s linear infinite;
}
@keyframes anim {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-7px);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(7px);
  }

  100% {
    transform: translateY(0);
  }
}
@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 650px) {
}

@media (max-width: 530px) {
}
