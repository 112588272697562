.app__header {
  background-color: rgba(0, 0, 0, 0.4);
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 1150px) {
  #home {
    padding: 0;
  }

  .app__wrapper_info {
    margin-left: 12rem;
  }
}

/* .css-1vscx9z {
  height: 10%;
} */

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 5.5rem;
}

img {
  width: 100%;
}

@media screen and (max-width: 1300px) {
  img {
    width: 80%;
  }

  .app__wrapper_info {
    margin-left: 10vw;
    /* width: 60vw; */
  }
}

@media screen and (min-width: 1300px) {
  .app__wrapper_info {
    margin-left: 13vw;
    /* width: 60vw; */
  }

  img {
    width: 70%;
  }
}

@media screen and (max-width: 1200px) {
  img {
    width: 90%;
  }

  .app__wrapper_info {
    margin-left: 6vw;
  }
}

@media screen and (min-width: 1150px) {
  #home {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 90px;
  }

  .app__wrapper_info {
    margin-left: 0px;
  }
  /* 
  .app__wrapper_info {
    padding: 0 8rem;
  } */

  img {
    margin-left: 2rem;
  }

  .app__wrapper_info {
    margin-left: 3vw;
  }

  .app__header-h1 {
    font-size: 6.3rem;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .app__header-h1 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 730px) {
  .app__header-h1 {
    font-size: 80px;
  }
}

@media screen and (max-width: 650px) {
  .app__header-h1 {
    font-size: 65px;
    line-height: 87px;
  }
}

@media screen and (max-width: 500px) {
  .app__header-h1 {
    font-size: 50px;
    line-height: 70px;
  }

  img {
    margin-left: 1rem;
  }

  /* .app__wrapper_info {
    margin-left: 0vw;
  } */
}

@media screen and (max-width: 400px) {
  .app__header-h1 {
    font-size: 3rem;
    line-height: 70px;
  }

  #home {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 340px) {
  .app__header-h1 {
    font-size: 2rem;
    line-height: 50px;
  }
}
