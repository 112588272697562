.app__journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  background: #000000;
  background: -webkit-linear-gradient(to right, #434343, #000000);
  background: linear-gradient(to right, #434343, #000000);
}
.app__journey-content {
  width: 100%;
  height: 75vh;
}
.app__journey-content_left {
  width: 85%;
  height: 100%;
}
.app__journey-content__img {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.images__journey {
  max-height: 100%;
  max-width: 100%;
}
.app__journey-content__text{
    padding-left: 30px;
}
.app__journey-content_right {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.timeline__journey {
  border-left: 1px solid var(--color-golden);
  width: 100%;
}
.arrows__journey {
  background: none;
  border: none;
  display: block;
  margin: auto;
}
.year__journey {
  background: none;
  border: none;
  margin: 5px auto;
  display: block;
}
.mobilearrows__journey {
  background: none;
  border: none;
  margin: auto;
  margin: auto;
  display: none;
}

@media (max-width: 1150px) {
  .app__journey-content__img {
    height: 60vh;
  }
  .mobilearrows__journey {
    display: inline;
  }
  .app__journey-content_right {
    display: none;
  }
}
@media (max-width: 992px) {
    .app__journey{
        min-height: 100vh;
    }
    .app__journey-content {
        height: 100%;
    }
}
